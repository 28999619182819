import React from "react";
import { Switch, Route, Redirect } from "react-router";
import "./App.css";
import CarDemoRoutes from "./layout/CarDemoRoutes";
import GameHostDemoRoutes from "./layout/GameHostDemoRoutes";
import MainRoutes from "./layout/MainRoutes";

function App() {
  return (
    <React.Fragment>
      <Switch>
        <Route path="/automotiveDemo" component={CarDemoRoutes} />
        <Route path="/gameHostDemo" component={GameHostDemoRoutes} />
        <Route path="/" component={MainRoutes} />
      </Switch>
    </React.Fragment>
  );
}

export default App;
