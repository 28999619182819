import React, { useState, useEffect } from "react";
import { keyframes } from "@emotion/core";
import styled from "@emotion/styled/macro";

type KeyframeProps = {
  scrollPos: number;
};

type Props = {
  text: string;
  className?: string;
};

//transform: rotateY(${props => props.scrollPos}deg);
const AText = styled.div<KeyframeProps>`
  position: absolute;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transform: translate3d(
      ${props => -(props.scrollPos / 20) * 3.2}px,
      ${props => Math.min(10 + (props.scrollPos * 3) / 100, 19)}vh,
      ${props => 1 - props.scrollPos / 100}vh
    )
    scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  opacity: ${props => props.scrollPos / 750};
`;

export function AnimatedText(props: Props) {
  const [scrollPos, setScrollPos] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPos(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <AText className={props?.className} scrollPos={scrollPos}>
      {props.text}
    </AText>
  );
}
