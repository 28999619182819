import React from "react";
import { Switch, Route, Redirect } from "react-router";
import { Layout } from "./Layout";
import AppHeader from "./AppHeader";
import HomePage from "../features/home";
import styled from "@emotion/styled";

const Header = styled.div`
  position: absolute;
  z-index: 10;
  width: 100%;
`;

export default function MainRoutes() {
  return (
    <Layout>
      <Header></Header>
      <Switch>
        <Route component={HomePage} exact path="/" />
        <Redirect to="/" />
      </Switch>
    </Layout>
  );
}
