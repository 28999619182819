import { css } from "@emotion/core";
import styled from "@emotion/styled/macro";

// common styled components
export const flex = css`
  display: flex;
`;
export const column = css`
  flex-direction: column;
`;
export const row = css`
  flex-direction: row;
`;

export const scrollY = css`
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

export const layout = props => css`
  ${flex};
  flex: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  ${props.type === "row" ? row : column};
`;

export const fill = props => css`
  ${props.flex && flex};
  flex: 1;
  position: relative;
  ${props.scroll
    ? scrollY
    : css`
        overflow-y: hidden;
      `};
  ${props.padded &&
  css`
    padding: 15px;
  `};
  background-color: hsl(0, 0%, 92%);
`;

export const Layout = styled.div`
  ${layout};
`;
Layout.displayName = "Layout";

export const Fill = styled.div`
  ${fill};
`;
Fill.displayName = "Fill";
