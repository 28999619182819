import { keyframes } from "@emotion/core";
import styled from "@emotion/styled/macro";
import React from "react";

const Card = styled.div`
  background-color: #262933;
  color: #f5f5f5;
  border: 1px solid #2b2f3a;
  text-align: center;
  padding: 1.25rem;
  flex: 1;
  margin-left: 30px;
  margin-right: 30px;
  font-size: 1.2em;

  * {
    margin-bottom: 5px;
  }
`;

const pulseAnim = keyframes`
0% {
    box-shadow: 0 0 0 0 rgba(66, 203, 111, 0.4);
}
70% {
    box-shadow: 0 0 0 10px rgba(66, 203, 111, 0);
}
100% {
    box-shadow: 0 0 0 0 rgba(66, 203, 111, 0);
}
`;

const Pulse = styled.span`
  vertical-align: top;
  margin-left: 6px;
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  background: #42cb6f;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(66, 203, 111, 0.4);
  animation: ${pulseAnim} 2s infinite;
`;

type Props = {
  title: string;
  ipaddress: string;
  playerCount: number;
  maxPlayerCount: number;
};

export function ServerCard(props: Props) {
  return (
    <Card>
      <h3>
        {props.title} <Pulse />
      </h3>
      <p>{props.ipaddress}</p>
      <h3>Players</h3>
      <p>
        {props.playerCount} / {props.maxPlayerCount}
      </p>
    </Card>
  );
}
