import styled from "@emotion/styled";
import React from "react";
import { ServiceComponent } from "../etc/ServiceComponent";
import Video from "../../assets/CarReelShort.mp4";

const VideoContainer = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
`;

const Page = styled.div`
  overflow: hidden;
  position: fixed;
  z-index: -2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transition: transform linear;
  transition: transform linear, -webkit-transform linear;
`;

const ContentWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.3);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100vh;

  > div > h1 {
    margin: -9.25vmin 0 0;
    width: 100%;
    font-size: 9.25vmin;
    letter-spacing: 0.185vmin;
    color: rgb(255, 255, 255);
    opacity: 1;
  }
`;

const Secondary = styled.div`
  position: absolute;
  bottom: 23vmin;
  display: flex;
  left: 0;
  width: 100%;
  height: 120px;
  text-align: center;
  justify-content: center;
  align-items: center;
  opacity: 0.25;
  background: black;
`;

const BodyWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: black;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Section = styled.div`
  display: block;
  bottom: 0;
  box-shadow: 0px -16px 40px 2px rgba(0, 0, 0, 0.75);
  :after {
    position: absolute;
    z-index: 9;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    content: "";
    background: #d40000;
  }
`;

export function AutoDemoHome() {
  return (
    <>
      <ContentWrapper>
        <div style={{ width: "70%", textAlign: "center" }}>
          <h1>MAKE A STATEMENT</h1>
        </div>

        {/* <Secondary>
        <Button>Test</Button>
      </Secondary> */}
        <Page>
          <VideoContainer autoPlay loop muted playsInline>
            <source
              src={require("../../assets/CarReelShort.mp4")}
              type="video/mp4"
            />
          </VideoContainer>
        </Page>
      </ContentWrapper>
      <Section></Section>
      <BodyWrapper>
        <ServiceComponent
          imageSource="stock-brake-img.jpg"
          route="/automotiveDemo/services"
          title="BRAKES"
        />
        <ServiceComponent
          imageSource="stick-jeep.jpg"
          route="/automotiveDemo/services"
          title="LIFT KITS"
        />
        <ServiceComponent
          imageSource="gen-maint.png"
          route="/automotiveDemo/services"
          title="GENERAL"
        />
        <ServiceComponent
          imageSource="auto_paint.jpg"
          route="/automotiveDemo/services"
          title="PAINT"
        />
        <ServiceComponent
          imageSource="engine.jpeg"
          route="/automotiveDemo/services"
          title="PERFORMANCE"
        />
        <ServiceComponent
          imageSource="col_repair.jpeg"
          route="/automotiveDemo/services"
          title="COLLISION REPAIR"
        />
      </BodyWrapper>
    </>
  );
}
