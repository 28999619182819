import styled from "@emotion/styled/macro";
import React from "react";
import { ServerCard } from "../../../components/ServerCard";

const PageRoot = styled.div`
  background: url(../map.webp);
  height: 100%;
  clear: both;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const ServerRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  padding-top: 60px;
  justify-content: space-evenly;
`;

export function TestServer() {
  return (
    <PageRoot>
      <h1>Test server, convince yourself</h1>
      <ServerRow>
        <ServerCard
          title={"Minecraft"}
          ipaddress={"178.33.168.228:25565"}
          playerCount={22}
          maxPlayerCount={60}
        ></ServerCard>
        <ServerCard
          title={"Minecraft"}
          ipaddress={"178.33.168.228:25565"}
          playerCount={22}
          maxPlayerCount={60}
        ></ServerCard>
        <ServerCard
          title={"Minecraft"}
          ipaddress={"178.33.168.228:25565"}
          playerCount={22}
          maxPlayerCount={60}
        ></ServerCard>
      </ServerRow>
    </PageRoot>
  );
}
