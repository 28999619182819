import React from "react";
import { Switch, Route, Redirect } from "react-router";
import { Layout } from "./Layout";
import AppHeader from "./AppHeader";
import { AutoDemoHome } from "../features/automotiveDemo/AutoDemoHome";
import styled from "@emotion/styled";
import { ServicesPage } from "../features/automotiveDemo/ServicesPage";
import ContactPage from "../features/automotiveDemo/ContactPage";

const Header = styled.div`
  position: absolute;
  z-index: 10;
  width: 100%;
`;

export default function CarDemoRoutes() {
  return (
    <Layout>
      <Header>
        <AppHeader />
      </Header>
      <Switch>
        <Route component={AutoDemoHome} exact path="/automotiveDemo" />
        <Route component={ServicesPage} path="/automotiveDemo/services" />
        <Route component={ContactPage} path="/automotiveDemo/contact" />
        <Redirect to="/automotiveDemo" />
      </Switch>
    </Layout>
  );
}
