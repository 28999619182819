import React from "react";
import styled from "@emotion/styled";

const HeaderContainer = styled.div`
  color: white;
  font-size: 1.66em;
  display: flex;
  width: 100%;
  justify-content: center;
  align-content: center;
  position: relative;
  margin-top: 400px;
`;

export function ServicesPage() {
  return (
    <>
      <HeaderContainer>Oops - There's nothing here yet!</HeaderContainer>
    </>
  );
}
