import React from "react";
import styled from "@emotion/styled/macro";
import { darken } from "polished";
import { Icon } from "semantic-ui-react";

type Props = {
  header: string;
  paragraph: string;
  color?: string;
  className?: string;
};

type StyleProps = {
  color: string;
};

const StyledParagraph = styled.p`
  font-size: 1.8em;
  color: #d6d6d6;
  padding: 12px;
`;

const StyledHeader = styled.h3<StyleProps>`
  color: white;
  padding: 12px;
  font-size: 2.2em;
  background: ${p => darken(0.1, p.color)};
  border-radius: 20px 20px 0px 0px;
`;

const PageRoot = styled.div<StyleProps>`
  display: flex;
  width: 40%;
  min-width: 400px;
  flex-direction: column;
  background: ${p => p.color};
  box-shadow: 10px 10px 16px 0px rgba(0, 0, 0, 0.75);
  border-radius: 20px 20px 20px 20px;
  border: 0px solid #000000;

  .info-icon {
    position: relative;
    left: 0;
    top: 0;
  }
`;

export default function InfoPanel(props: Props) {
  return (
    <PageRoot
      className={props.className ?? ""}
      color={props.color ?? "#095b77"}
    >
      <StyledHeader color={props.color ?? "#095b77"}>
        {props.header}
      </StyledHeader>
      <StyledParagraph>{props.paragraph}</StyledParagraph>
    </PageRoot>
  );
}
