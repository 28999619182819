import React from "react";
import styled from "@emotion/styled/macro";
import "./index.css";
import { CardComponent } from "./CardComponent";
import { AnimatedText } from "./AnimatedText";
import work from "../../assets/work.jpg";
import { Button, Icon } from "semantic-ui-react";

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 50vh;

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;

`;

const Container = styled.div`
  .animated-text {
    font-size: 4rem;
    position: absolute;
    top: 80vh;
    right: 2rem;
  }

  margin-bottom: 100vh;
`;

const Avatar = styled.div<ImageProps>`
  background: #fff;
  transition: transform 350ms;
  transform-style: preserve-3d;
  background-image: url(${i => i.src});
  background-size: 100%;
  background-position: 50%;
`;

const AvatarContainer = styled.div`
  position: absolute;
  top: 35%;
  left: 50%;
  :hover {
    * {
      transform: rotateY(-360deg);
    }

    .overlay {
      transform: rotateY(-360deg);
      opacity: 1;
    }
  }
`;

const AvatarOverlay = styled.div`
  background: #282828;
  transition: all 350ms;
  transform-style: preserve-3d;
  opacity: 0;
  top: 0;
  left: 0;
  position: absolute;
  text-align: center;
  padding-top: 10rem;

  .media {
    bottom: 30%;
    position: relative;
  }
`;

const AvatarOutline = styled.div`
  background: transparent;
  top: 0;
  left: 0;
  position: absolute;
  width: 22rem;
  height: 22rem;
  border-radius: 22rem;
  border-color: #fff;
`;

type ImageProps = {
  src: string;
};

export default function HomePage() {
  return (
    <Container>
      <div className="header">
        <h1>Web Design</h1>
        <h2>Reimagined</h2>
        <AvatarContainer className="avatar-class">
          <Avatar className="avatar-class circle" src={work}></Avatar>
          <AvatarOverlay className="overlay avatar-class circle">
            <a
              className="media"
              href="https://www.linkedin.com/in/nathan-murphy-518542153/"
            >
              <Icon name="linkedin" size="massive"></Icon>
            </a>
          </AvatarOverlay>
        </AvatarContainer>
        <div className="inner-header"></div>
        <svg
          className="waves"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
          shapeRendering="auto"
        >
          <defs>
            <path
              id="gentle-wave"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g className="parallax">
            <use href="#gentle-wave" x="48" y="0" fill="rgba(40,40,40,0.7" />
            <use href="#gentle-wave" x="48" y="3" fill="rgba(40,40,40,0.5)" />
            <use href="#gentle-wave" x="48" y="5" fill="rgba(40,40,40,0.3)" />
            <use href="#gentle-wave" x="48" y="7" fill="rgba(40,40,40,0.2)" />
            <use href="#gentle-wave" x="48" y="13" fill="rgba(40,40,40,1)" />
          </g>
        </svg>
      </div>

      <CardContainer>
        <CardComponent
          text="Demo #1"
          detailText="This is an automotive sample site complete with a minimalist style
            and a catchy video."
        />
      </CardContainer>
      <AnimatedText className="animated-text" text="My Work"></AnimatedText>
    </Container>
  );
}
