import React from "react";
import { Switch, Route, Redirect } from "react-router";
import { GameHostDemoHome } from "../features/gameHostDemo/GameHostDemoHome";
import { Layout } from "./Layout";

export default function GameHostDemoRoutes() {
  return (
    <Layout>
      <Switch>
        <Route component={GameHostDemoHome} exact path="/gameHostDemo" />
        <Redirect to="/gameHostDemo" />
      </Switch>
    </Layout>
  );
}
