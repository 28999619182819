import styled from "@emotion/styled/macro";
import React from "react";

type Props = {
  title: string;
  link: string;
};

const StyledA = styled.a`
  justify-self: center;
  align-self: center;
  margin-left: 2em;
  margin-right: 2em;
  color: white;
  font-size: 1.35em;
`;

export function NavItem(props: Props) {
  return <StyledA href={props.link}>{props.title}</StyledA>;
}
