import React from "react";
import styled from "@emotion/styled";
import { withMousePosition } from "./hooks/withMouseTracker";
import { keyframes } from "@emotion/core";
import { Button } from "semantic-ui-react";

const Container = styled.div`
  width: 260px;
  height: 400px;
  margin: 30px;
  float: left;
  -webkit-perspective: 1000px;
  -moz-perspective: 1000px;
  perspective: 1000px;
`;

const Info = styled.div<ImageProps>`
  transform: rotateY(90deg) translateZ(130px);
  border: 1px solid #b8b5b5;
  font-size: 0.75em;
  box-shadow: inset -300px 0px 40px rgba(0, 0, 0, 0.8);
  transition: box-shadow 350ms;
  position: absolute;
  width: 260px;
  height: 400px;
  background-color: #fff;
  backface-visibility: hidden;
  background-image: url(${p => p.src});
  background-size: 450%;
  background-position: 90%;

  .nav-button {
    position: relative;
    top: 20%;
    left: 30%;
  }
`;

const InfoText = styled.div`
  width: 80%;
  margin: auto;
  padding-top: 20%;
  font-size: 1.5rem;
`;

const Card = styled.div`
  width: 260px;
  height: 400px;
  transform-style: preserve-3d;
  transform: translateZ(-130px);
  transition: transform 350ms;

  :hover {
    transform: rotateY(-78deg) translateZ(20px);
  }
`;

const gradient = keyframes`{
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: 400% 400%;
    }
  }`;

const Front = styled.div`
  position: absolute;
  width: 260px;
  height: 400px;
  background-color: #fff;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: translateZ(130px);
  background: linear-gradient(90deg, #726cf8, #e975a8, #f0416c, #726cf8);
  background-size: 400%;
  animation: ${gradient} 32s linear infinite;
`;

const VerticalText = styled.p`
  font-size: 4rem;
  font-weight: 400;
  letter-spacing: 10px;
  text-align: center;
  color: #fff;
`;

type ImageProps = {
  src: string;
};

export function CardComponent(props) {
  return (
    <Container>
      <Card>
        <Front>
          <VerticalText>{props.text}</VerticalText>
        </Front>
        <Info src="AutoDemoScreen.png">
          <InfoText>{props.detailText}</InfoText>
          <Button
            as={"a"}
            href="/automotiveDemo"
            color="red"
            inverted
            className="nav-button"
          >
            Click Me!
          </Button>
        </Info>
      </Card>
    </Container>
  );
}
