import styled from "@emotion/styled/macro";
import React from "react";
import { NavItem } from "../../components/NavItem";
import { TestServer } from "./features/TestServer";

const PageRoot = styled.div`
  display: flex;
  margin-left: 5%;
  margin-right: 5%;
`;

const PageHeader = styled.div`
  margin-bottom: 0;
  background: rgb(26, 28, 36, 0.6);
  box-shadow: 0 7px 17px rgba(0, 0, 0, 0.04);
  border: 0;
  min-height: 38px;
  width: 100%;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export function GameHostDemoHome() {
  return (
    <>
      <PageHeader>
        <NavItem title="Home" link="/" />
        <NavItem title="Home" link="/" />
        <NavItem title="Home" link="/" />
        <NavItem title="Home" link="/" />
      </PageHeader>
      <PageRoot>
        <TestServer />
      </PageRoot>
    </>
  );
}
