import React, { useState } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Button, Dropdown, Icon, Image, Label, Menu } from "semantic-ui-react";
import styled from "@emotion/styled/macro";
import { ToastContainer } from "react-toastify";
import carLogo from "../assets/carLogo.svg";

// constants
// import { APP_NAME } from "../../constants";
import { carDemoColors } from "../styles";

const AppHeaderRoot = styled.div`
  height: 62px;
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  transition: transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(0, 0, 0, 0.35);
  color: ${carDemoColors.white};
`;

const Logo = styled.a`
  display: flex;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  align-items: center;

  > img {
    margin-right: 20px;
    margin-left: 4.5vmin;
    cursor: pointer;
    z-index: 10;
  }

  > span {
    visibility: hidden;
    width: 160px;
    top: 100%;
    margin-left: 10px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
  }

  :hover > span {
    visibility: visible;
  }

  > span::after {
    content: " ";
    position: absolute;
    bottom: 100%; /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }
`;

const Navigation = styled.div`
  flex: 1 1 auto;
`;

const NavContainer = styled.div`
  height: 100%;

  @media only screen and (max-width: 1094px) {
    display: none;
  }
`;

const NavContainerAccordian = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  top: calc(1.5375em - 4px);
  cursor: pointer;
  right: 4.5vmin;
  display: none;
  z-index: 6;

  @media only screen and (max-width: 1094px) {
    display: block;
  }
`;

const NavOverlay = styled.ul<Props>`
  display: ${props => (props.isVisible ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: auto;
  width: 100%;
  height: 100vh;
  transition: background 250ms linear, opacity 250ms linear;
  opacity: 1;
  background: rgba(0, 0, 0, 0.9);
  z-index: 5;
  list-style: none;
`;

const StyledLi = styled.li`
  margin-top: 50px;
  font-size: 1.66em;
  > a {
    text-decoration: none;
    background-color: transparent;
    color: white;

    :hover {
      letter-spacing: 0.125em;
    }
  }
`;

const List = styled.ul`
  position: relative;
  height: 100%;
  text-align: center;
  letter-spacing: 0.1025em;
  background: 0 0;
  width: calc(100% - 100px);

  > li {
    list-style: none;
    display: inline-block;
    position: relative;
    margin: 0 1em;
    transition-timing-function: cubic-bezier(0.515, 0.005, 0.515, 1);
    transition-duration: 0.8s;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    vertical-align: top;
    opacity: 1;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    height: 100%;
    top: 0;
    box-sizing: border-box;

    > a {
      display: block;
      transition-timing-function: linear;
      transition-duration: 0.3s;
      transition-property: color, border;
      text-decoration: none;
      border-bottom: 4px solid transparent;
      outline: 0;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      display: flex;
      align-items: center;
      height: 100%;
      padding-bottom: 0;
      box-sizing: border-box;
      color: rgba(255, 255, 255, 0.5);

      :hover {
        border-bottom: 1px solid white;
        color: white;
      }
    }
  }
`;

type Props = {
  isVisible: boolean;
};

export function AppHeader(props: any) {
  const [overlay, setOverlay] = useState(false);

  const _handleOverlay = () => {
    setOverlay(!overlay);
  };

  return (
    <AppHeaderRoot>
      <Logo href="/">
        <img src={carLogo} alt="Your Logo Here"></img>
        <span>This could be your logo!</span>
      </Logo>
      <Navigation>
        <NavContainer>
          <List>
            <li>
              <a href="/automotiveDemo/services">Services</a>
            </li>
            <li>
              <a href="/">Appointments</a>
            </li>
            <li>
              <a href="/">Gallery</a>
            </li>
            <li>
              <a href="/automotiveDemo/contact/">About Us</a>
            </li>
            <li>
              <a href="/automotiveDemo/contact">Contact</a>
            </li>
          </List>
        </NavContainer>
        <NavContainerAccordian>
          <Button color="black" icon="bars" onClick={_handleOverlay}></Button>
        </NavContainerAccordian>
      </Navigation>
      <NavOverlay isVisible={overlay}>
        <StyledLi>
          <a href="/automotiveDemo/services">Services</a>
        </StyledLi>
        <StyledLi>
          <a href="/automotiveDemo/services">Appointments</a>
        </StyledLi>
        <StyledLi>
          <a href="/automotiveDemo/services">Gallery</a>
        </StyledLi>
        <StyledLi>
          <a href="/automotiveDemo/services">About Us</a>
        </StyledLi>
        <StyledLi>
          <a href="/automotiveDemo/contact">Contact</a>
        </StyledLi>
      </NavOverlay>
    </AppHeaderRoot>
  );
}

export default withRouter(AppHeader);
