import React from "react";
import styled from "@emotion/styled";

const Container = styled.a`
  flex: 0 1 100%;
  z-index: 1;
  text-align: center;
  position: relative;
  color: white;
  background-color: transparent;
  pointer-events: inherit;

  :hover {
    color: #d40000;
  }

  :hover > h4 {
    background: rgba(0, 0, 0, 0);
  }
`;

const Item = styled.div`
  display: inline-flex;
  width: 50%;
  height: 35vh;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  z-index: 0;
  cursor: pointer;
  pointer-events: inherit;

  :hover > .image {
    transform: perspective(500px) translateZ(30px);
  }
`;

const Mask = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  transition: opacity 225ms linear 225ms;
  opacity: 0;
  background: rgba(0, 0, 0, 0.75);
  pointer-events: inherit;

  :hover {
    opacity: 1;
  }
`;

const Image = styled.div<ImageSource>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-image: url(${props => props.src});
  background-position: 40%;
  pointer-events: inherit;
  transition: transform 450ms linear;
`;

const Title = styled.h4`
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  padding: 2vmin;
  font-size: 4.625vmin;
  transition: opacity 225ms linear 225ms;
  opacity: 1;
  bottom: 0;
  pointer-events: none;
  transition: background 225ms linear 225ms;
  background: rgba(0, 0, 0, 0.3);
`;

type Props = {
  title: string;
  route: string;
  imageSource: string;
};

type ImageSource = {
  src: string;
};
export function ServiceComponent(props: Props) {
  return (
    <Item>
      <Image src={props.imageSource} className="image"></Image>
      <Container href={props.route}>
        <Mask className="mask"></Mask>
        <Title>{props.title}</Title>
      </Container>
    </Item>
  );
}
