import { keyframes } from "@emotion/core";
import styled from "@emotion/styled/macro";
import React from "react";
import InfoPanel from "../../components/InfoPanel";

const PageRoot = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5em;
  margin-right: 5em;
  height: 100vh;
  justify-content: space-evenly;
`;

const fadeIn = keyframes`
  from, 0%, to {
    opacity: 0%;
  }

  100% {
    opacity: 100%;
  }
`;

type StyleProps = {
  delayTime: string;
};

const AnimatedInfoPanel = styled(InfoPanel)<StyleProps>`
  opacity: 0;
  animation: ${fadeIn} ease 2s;
  animation-delay: ${p => p.delayTime};
  animation-fill-mode: forwards;
`;

const CenteredInfoPanel = styled(AnimatedInfoPanel)`
  align-self: center;
`;

export default function ContactPage() {
  return (
    <PageRoot>
      <AnimatedInfoPanel
        header="Experienced Developer"
        paragraph="With over 3 years of professional software development experience with with the latest technologies, I am confident we can craft any solution for your business."
        color="#2660A4"
        delayTime="0s"
      />
      <CenteredInfoPanel
        header="Dedicated Craftsman"
        paragraph="Web development and design is a way of life. Personal projects (such as this website) are one of the many of ways of honing the craft and keeping up with industry trends."
        color="#C47335"
        delayTime="1s"
      />
      <AnimatedInfoPanel
        header="Community Driven"
        paragraph="I volunteer at Southeastern Louisiana University to help build and inspire the next generation of Computer Science graduates."
        color="#246A73"
        delayTime="2s"
      />
    </PageRoot>
  );
}
